@import "./color";
@import "../base/mixins";

.customNavbarReports {
  display: flex;
  justify-content: space-around;
  color: $dotNavyBlue;
  font-size: 100%;
  font-weight: 600;

  .bottomActive {
    display: none;
    height: 5px;
    width: 120px;
    background: orange;
    border-radius: 5px;
  }

  .navMenu {
    text-align: center;
    width: 120px;
    height: 22px;

    .active {
      display: block !important;
    }
  }
}

.dropDownWrapper {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 10px;
}

.chart-container-height-vat {
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  .ui.toggle.checkbox {
    min-height: 0 !important;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background: $dotOrrange;
  }

  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    width: 1.4rem !important;
    height: 1.4rem !important;
    top: -3px !important;
    background-color: #fff;
  }

  .ui.toggle.checkbox input:checked~.box:before,
  .ui.toggle.checkbox input:checked~label:before {
    background: $dotNavyBlue;
  }

  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    height: 1rem !important;
  }

  .ui.toggle.checkbox .box,
  .ui.toggle.checkbox label {
    min-height: 1rem !important;
  }
}

.reportSegment {
  padding-bottom: 2px !important;
  box-shadow: none !important;
  border: none !important;
}

.cusColumnReport {
  padding-left: 0.4rem !important;
  padding-right: 0.4rem !important;
}

.cusRowReport {
  margin-left: 0.6rem !important;
  margin-right: 0.6rem !important;
}

.cusSegmentSmallReport {
  height: 100%;
  padding: 0px 20px !important;
  // height: auto;
  // div {
  // transform: scale(0.5);
  // transform: scale(0.9);
  // margin: calc(-150px * (1 - 0.9)) auto calc(-150px * (1 - 0.9));
  // }
  // overflow: overlay;
  // height: 250px;
}

.cusSegmentSmallReportFirst {
  // div {
  // transform: scale(0.5);
  // transform: scale(0.7);
  // margin: calc(-150px * (1 - 0.68)) auto calc(-150px * (1 - 0.58));
  // }
  // overflow: overlay;
  overflow-x: overlay;
  height: 100%;
  overflow-y: clip;
}

.reportSpinner2 {
  margin-top: 40% !important;
}

.reportSpinner1 {
  margin-top: 12% !important;
  margin-bottom: 12% !important;
}

.chartTopDiv {
  display: flex;
  justify-content: space-between;
}

.bt-32 {
  bottom: 32px;
}

.reportHeader {
  background: $dotOrrange !important;
  color: $dotWhite !important;
  border-left: 0.5px solid $dotWhite;
  border-right: 0.5px solid $dotWhite;
  border-bottom: 0.5px solid $dotDarkGrey;
}

.header {
  color: rgb(103, 93, 93);
  font-weight: bolder;
  opacity: 25;
  text-align: center;
}

.nameDrop {
  float: right;
  align-items: right;
  margin-left: 60% !important;
  margin-top: 7px;
}

.tableHeader {
  align-content: center;
  background: $dotNavyBlue !important;
  color: $dotWhite !important;
  border-left: 0.5px solid $dotWhite;
  border-right: 0.5px solid $dotWhite;
  border-bottom: 0.5px solid $dotDarkGrey;
}

.nameDrop2 {
  float: right;
  margin-top: 7px;
}

.reportHeaderHeight {
  background: $dotOrrange !important;
  color: $dotWhite !important;
  font-weight: bold;
  font-family: Quicksand !important;
  font-size: 18px;
  border-left: 0.5px solid $dotWhite;
  border-right: 0.1px solid $dotWhite;
  border-bottom: 0.5px solid $dotDarkGrey;
  height: 50px !important;
}

.border-bottom {
  border-bottom: 0.5px solid $dotWhite !important;
}

.tableBody {
  font: normal normal medium 15px/18px Quicksand !important;
  color: $dotNavyBlue;
  font-weight: bold;
  font-family: Quicksand !important;
  font-size: 18px;
  height: 150px;
}

.dividerVat {
  opacity: 75;
  width: 3px;
  margin: 6px 0;
  background: #4472c4;
}

.headers {
  font-size: medium !important;
  // font-weight: bold;
  color: #193256 !important;
}

.headersEnable {
  font-size: medium !important;
  // font-weight: bold;
  color: $dotOrrange;
}

.headersDisable {
  font-size: medium !important;
  // font-weight: bold;
  color: gray;
}

.TimeActualBill {
  overflow-y: auto;
}

.alignDropDown {
  float: right !important;
}

.treeMapReport {
  overflow-x: auto;
}

.headerHeight {
  height: 40px;
}

.dropIndex {
  z-index: 9999;
}

.dropIndexMulti {
  .css-1jllj6i-control {
    right: 8px !important;
  }

  .css-11unzgr {
    min-height: 240px !important;
  }
}

.dropIndexSpl {
  z-index: 12999;
}

.dropIndexStatus {
  z-index: 19999 !important;
}

.dropIndexEduc {
  z-index: 29999;
}

.ml-report {
  margin-left: 30%;
}

.reportPosition {
  position: sticky !important;
}

.accessCheck {
  pointer-events: auto;
  cursor: pointer;
}

.accessCheckNone {
  pointer-events: none;
  cursor: pointer;
}

.fixed {
  border-spacing: 0;
}

.boarder-left {
  border-right: 0px;
}

.scroll {
  width: 200px;
  overflow-x: scroll;
}

.scrollable {
  width: 100px;
  height: 100%;
  border-spacing: 0;
  border: 0 !important;
}

.revenue-cell {
  border-radius: 0px !important;
  border-left: 0.5px solid #ffffff !important;
  border-right: 0.5px solid #ffffff !important;
}

.margin-top-reports {
  margin: 0 !important;
  border: 0 !important;
}

.b-0 {
  border: 0 !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-50 {
  width: 90% !important;
}

.column-min-width {
  width: 100px !important;
  padding-right: 73px !important;
}

.monthRevenue {
  margin-bottom: 10px !important;
}

.total_monthrevenue {
  font-weight: bold !important;
}

.month_revenue_table {
  margin-bottom: 10px !important;
}

// actual leaves
.Leave_Without_Pay {
  background-color: $dotDarkOrange;
}

.CreditLeave {
  background-color: $dotPink;
}

.PaidLeave {
  background-color: $dotLimeGreen;
}

.WeekOff {
  background-color: $dotGrey;
}

.Holiday {
  background-color: $dotblue;
}

.Absent {
  background-color: $dotRedL;
}

.Regularization {
  background-color: $dotYellow;
}

.MaternityLeave {
  background-color: $dotGreenL;
}

.PrivilegedLeave {
  background-color: $dotLightGreenL;
}
.PL_P{
  background:$dotNavyBlue;
}
.table-scr{
  max-height: 250px;
}
.monthRevenue {
  margin-bottom: 10px !important;
}

.total_monthrevenue {
  font-weight: bold !important;
}

.month_revenue_table {
  margin-bottom: 10px !important;
}

.staffLeaveTable {
  border-bottom: 1px solid #b3b5b4 !important;
  border: none;
  font-size: 14px;
}

.w-100 {
  width: 100%;
}

.staffLeaveTable tr:first-child>td:first-child {
  border-radius: 0.28571429rem 0 0 0;
}

.MembersInLopExport{
  display: flex;
  justify-content: space-between;
}


.MembersInLopExport div:first-child{
  display: flex;
  justify-content: center;
  align-items: center;
}
.MembersInLopExport div:last-child{
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-class-membersInLop{
  padding: 0 10rem;
 
}


.margin-top-class div{
  
  padding-top: 1rem;
  

}



.report-main-div{
  display: flex;
  width: 100%;
  flex-wrap: wrap;

}


.report-main-div .report-sub-div{
  margin: 0.5rem 0;
  width: 33%;
  max-height: 600px;
  min-height: 380px;
}

.report-main-div div:first-child{
  cursor: pointer;
}


.label-class label{
  margin-right:1rem;
}