html {
  scroll-behavior: smooth;
}

body {
  overflow: hidden;
}

@import "./styles/index.scss";

// body ::-webkit-scrollbar {
//     -webkit-appearance: none;
//     width: 8px;
//     height: 10px;

// }

// body ::-webkit-scrollbar-track {
//     background: rgba(#193256, .8);
//     border-radius: 0;
// }

.ui-grid {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.full-container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.secondary {
  background: #f18a00 0% 0% no-repeat padding-box !important;
  opacity: 1;
}

.secondary-outlined {
  background-color: transparent !important;
  border: 1px solid $dotOrrange  !important;
  color: $dotOrrange  !important;

  &:disabled {
    border-color: rgba($dotOrrange, 0.5) !important;
    color: rgba($dotOrrange, 0.5) !important;
  }

  &.top16 {
    margin-top: 16px !important;
  }
}

.blurring.dimmable>.dimmer {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.table-option-popup {
  padding: 1em 0 0 0;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-x {
  overflow-x: auto;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1 !important;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 1 !important;
}

.text-underline {
  &:hover {
    text-decoration: underline;
  }

  &.force {
    text-decoration: underline;
  }
}

.modal {
  .button {
    padding: 0.8em;
  }
}

.custom-table {
  &::-webkit-scrollbar {
    height: 15px !important;
  }
}

.margin-0 {
  margin: 0 !important;
}

.my-swipe-options {
  max-height: 300px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
}

.my-swipe-list {
  width: 100%;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  .swipe-container {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    div {
      min-width: 130px;
    }
  }
}

.no-record-found2 {
  align-self: center;
  min-height: 400px;
  max-height: 400px;
}

.content-editable-input {
  outline: none;
  border: none;
  background-color: inherit;
}

.btn-transparent {
  outline: none;
  border: none;
  background-color: transparent;
}

th.table-with-drop-down.table-header.Approved.Time {
  border-top: 0px;
}

.table-body.fixed-row:first-child {
  display: flex;
}

.tableHover {
  .checkBox {
    display: block !important;
  }
}

.Jobslisting {
  .table-body.fixed-row:first-child {
    padding: 1.185714em 0.78571429em;
  }
}

.blank-header {
  padding: 1.185714em 0.78571429em;
}

.TimeKeeping,
.TimevsActualBill,
.ProductivityMark {
  tr:last-child {
    .table-body.fixed-row:first-child {
      padding: 1.485714em 0.785714em;
    }
  }
}

/* Container for the attachments */
.attachment-container {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
}

/* Header container for title and button */
.attachment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Title styling */
.attachment-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Button styling */
.attachment-button {
  background-color: white;
  border: 2px solid orange;
  color: orange;
  padding: 5px 15px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}

/* Hover effect for the button */
.attachment-button:hover {
  background-color: orange;
  color: white;
}

/* Content container for attachments */
.attachment-content {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
}
